.css-1kb3csv{
    width: auto;
}

.css-1tlib9g {
    display: grid;
    width: auto;
    appearance: none;
    border: none;
    gap: 16px;
    grid-template-columns: 1fr;
}

.css-3js0wx {
    -webkit-box-align: center;
    align-items: center;
    background-color: transparent;
    border-radius: 0.5rem;
    color: rgb(41, 43, 41);
    display: flex;
    flex-direction: row;
    height: 64px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 0px 20px;
    text-align: left;
    width: auto;
    border: 1px solid rgb(164, 168, 164);
    font-family: "Better Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
    font-size: 15px;
    font-weight: 600;
    transition-duration: 300ms;
    transition-property: background-color, color, box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0.8, 0.6, 1);
}

.css-3js0wx:hover, .css-3js0wx:focus-within, .css-3js0wx:active {
    border-color: transparent;
    box-shadow: rgb(1, 120, 72) 0px 0px 0px 4px inset;
    color: rgb(1, 120, 72);
}

.css-1etrdwv {
    align-self: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-left: 16px;
    margin-right: 24px;
    width: auto;
}