.dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    backdrop-filter: blur(5px); /* apply blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-box {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.header {
    display: flex;
    align-items: center;
}

.header img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.content {
    margin-top: 20px;
}

.footer {
    margin-top: 20px;
    text-align: right;
}

.otp-input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.otp-input-container input {
    width: 40px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}
